import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { Button, CircularProgress, Modal } from '@mui/material';

import SessionProvider from '@/contexts/SessionContext';
import useSubscription from '@/hooks/account/useSubscription';
import useIntercomContext from '@/hooks/context/useIntercomContext';
import useWebsite from '@/hooks/website-builder/useWebsite';
import useFeatureFlags from '@/services/FeatureFlag';

import MetaAdsModal from '../promote-modals/MetaAdsModal';
import PlaylistingModal from '../promote-modals/PlaylistingModal';
import TikTokAdsModal from '../promote-modals/TikTokAdsModal';
import WebsiteGeneratorModal from '../promote-modals/WebsiteGeneratorModal';
import Subscription from '../subscription/Subscription';
import ButtonComponent from '../utility/microcomponents/Button';

const PromotionCards = () => {
  const [params] = useSearchParams();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { isSubscribed } = useSubscription();
  const { isPlaylistingDisabled, isFanHubDisabled, isMetaAdsDisabled, isTikTokAdsDisabled } = useFeatureFlags();

  const { dispatchActivateIntercom, dispatchDeactivateIntercom } = useIntercomContext();

  const { website, websiteIsLoading, websiteError } = useWebsite();

  const [open, setOpen] = useState<boolean>(false);
  const [modalItem, setModalItem] = useState<string>();
  const [isWebsite, setIsWebsite] = useState<boolean>(false);

  const promoteState = useMemo(() => params.get('promoteState'), [params]);

  useEffect(() => {
    if (!website || websiteError) return;

    setIsWebsite(true);
  }, [website, websiteError]);

  const promoteItems = [
    {
      name: 'playlisting',
      title: 'HOMEPAGE.PLAYLISTING',
      description: 'PROMOTE-PAGE.PLAYLISTING-DESCRIPTION',
      isDisabled: false || isPlaylistingDisabled,
      isProFeature: false,
    },
    {
      name: 'website',
      title: 'HOMEPAGE.FAN-HUB',
      description: 'PROMOTE-PAGE.WEBSITE-DESCRIPTION',
      isDisabled: !isSubscribed || isFanHubDisabled,
      isProFeature: true,
    },
    {
      name: 'meta',
      title: 'HOMEPAGE.FACEBOOK-AND-INSTAGRAM-ADS',
      description: 'PROMOTE-PAGE.META-ADS-DESCRIPTION',
      isDisabled: false || isMetaAdsDisabled,
      isProFeature: false,
    },
    {
      name: 'tiktok',
      title: 'HOMEPAGE.TIKTOK-ADS',
      description: 'PROMOTE-PAGE.TIKTOK-ADS-DESCRIPTION',
      isDisabled: false || isTikTokAdsDisabled,
      isProFeature: false,
    },
  ];

  const handleOpenTodo = (todo: string) => {
    setModalItem(todo);
    setOpen(true);
  };

  useEffect(() => {
    if (promoteState) {
      handleOpenTodo(promoteState);
    }
  }, [promoteState]);

  const handleModalClose = () => {
    if (location.pathname.includes('home')) {
      dispatchActivateIntercom('home');
    }
    setOpen(false);
    setModalItem(undefined);
    if (promoteState) {
      if (location.key !== 'default') {
        navigate(-1);
      } else {
        navigate('home');
      }
    }
  };

  useEffect(() => {
    if (
      (modalItem === 'website' && (!isSubscribed || isFanHubDisabled)) ||
      (modalItem === 'playlisting' && isPlaylistingDisabled) ||
      (modalItem === 'meta' && isMetaAdsDisabled) ||
      (modalItem === 'tiktok' && isTikTokAdsDisabled)
    ) {
      setOpen(false);
    }
  }, [isFanHubDisabled, isMetaAdsDisabled, isPlaylistingDisabled, isSubscribed, isTikTokAdsDisabled, modalItem]);

  const handleOpenModal = (name: string) => {
    if (location.pathname.includes('home')) {
      dispatchDeactivateIntercom('home');
    }
    setModalItem(name);
    setOpen(true);
  };

  return (
    <>
      <Modal open={open} onClose={handleModalClose}>
        <>
          {modalItem === 'playlisting' && (
            <SessionProvider>
              <PlaylistingModal closeModalOutput={handleModalClose} />
            </SessionProvider>
          )}
          {modalItem === 'website' && isSubscribed && <WebsiteGeneratorModal closeModalOutput={handleModalClose} />}
          {modalItem === 'meta' && <MetaAdsModal closeModalOutput={handleModalClose} />}
          {modalItem === 'tiktok' && <TikTokAdsModal closeModalOutput={handleModalClose} />}
        </>
      </Modal>
      <div className="d-flex flex-wrap gap20">
        {promoteItems.map((item, index: number) => {
          if (item.isDisabled) {
            return (
              <div key={index} className={`flex-w50p flex-grow`}>
                <Subscription
                  type="element"
                  element={
                    <div
                      key={index}
                      className={`card min-h150 background-card ${item.name} cursor-pointer disabled text-left`}
                      style={
                        {
                          '--animation-number': `${index}`,
                        } as React.CSSProperties
                      }
                    >
                      <div className="d-flex">
                        <h3>{t(item.title)}</h3>
                        {item.isDisabled && item.isProFeature && (
                          <img className="ml-auto" src="/images/logos/subscription-pro-lock-logo.svg" alt="lock" />
                        )}
                      </div>
                      <p className="mt16 small">{t(item.description)}</p>
                      <Button onClick={() => null} className="btn-white ml0" disabled={true}>
                        {item.name === 'website' && !isSubscribed
                          ? t('PROMOTE-PAGE.GET-STARTED')
                          : t('COMMON.UPGRADES-COMING')}
                      </Button>
                    </div>
                  }
                />
              </div>
            );
          } else {
            return (
              <ButtonComponent
                isCustom={true}
                key={index}
                className={`card min-h150 background-card ${item.name} cursor-pointer text-left`}
                style={{ '--animation-number': `${index}` } as React.CSSProperties}
                onClick={() => {
                  if (!item.isDisabled) {
                    handleOpenModal(item.name);
                  }
                }}
              >
                <div>
                  <h3>{t(item.title)}</h3>
                  {item.isDisabled && (
                    <img className="ml-auto" src="/images/logos/subscription-pro-lock-logo.svg" alt="lock" />
                  )}
                  <p className="mt16 small">{t(item.description)}</p>
                  <ButtonComponent onClick={() => handleOpenModal(item.name)} className="btn-white ml0">
                    {item.name !== 'website' && t('PROMOTE-PAGE.GET-STARTED')}
                    {item.name === 'website' &&
                      !websiteIsLoading &&
                      isWebsite &&
                      t('WEBSITE-BUILDER.UPDATE-MY-WEBSITE')}
                    {item.name === 'website' && !websiteIsLoading && !isWebsite && t('PROMOTE-PAGE.GET-STARTED')}
                    {item.name === 'website' && websiteIsLoading && <CircularProgress size={16} />}
                  </ButtonComponent>
                </div>
              </ButtonComponent>
            );
          }
        })}
      </div>
    </>
  );
};

export default PromotionCards;
